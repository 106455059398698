import * as React from 'react';
import Script from 'next/script';
import Head from 'next/head';
import { useState } from 'react';
import { WorkWithUsModal } from '../components/workWithUsModal';
import { PageData } from '../core/sanityAPI/fetchers';
import { PageSchema } from '../schema';
import { renderBlock } from '../blocks/mapping';
import { getStaticProps as slugGetStaticProps } from '@/pages/[...slug]';
import { extractOG } from '@/core/utils';

const IndexPage = (props: PageData<PageSchema>) => {
  const [isModalShown, setIsModalShown] = useState(false);

  const og = extractOG(props.body);

  return (
    <>
      <Head>
        <meta property="og:title" content={og.title} />
        <meta property="og:image" content={og.imageUrl} />
        <meta property="og:description" content={og.description} />
        <title>{og.title || 'Techery'}</title>
        <link rel="preconnect" href="https://www.googletagmanager.com" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#002295" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <main>
        {props.body.blocks?.map((block, index) => {
          return renderBlock(block, index);
        })}
      </main>
      <WorkWithUsModal
        isModalShown={isModalShown}
        setIsModalShown={setIsModalShown}
      />
      <Script
        defer
        src="https://www.googletagmanager.com/gtag/js?id=G-PKLYKZRJE0"
        strategy="lazyOnload"
      ></Script>
      <Script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-PKLYKZRJE0');
        `}
      </Script>
    </>
  );
};

export const getStaticProps = slugGetStaticProps;
export default IndexPage;
